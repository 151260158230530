<template>
  <section class="look">
    <div class="container">
      <div class="look__title">
        <h1>{{ $t('cases_page.oversecured.title') }}</h1>
      </div>
      <p class="look__text ">{{ $t('cases_page.oversecured.desc') }}</p>
    </div>
  </section>

  <section class="presentation">
    <div class="container">
      <picture>
        <source srcset="../assets/cases/oversecured/cover_oversecured.webp" type="image/webp">
        <img class="presentation__image" src="../assets/cases/oversecured/cover_oversecured.png" alt="" loading="lazy" />
      </picture>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <div class="line-wrapper">
        <div class="about__inner">
          <div class="about__tech">
            <div class="about__tech-item">
              <h3 class="about__tech-desc fonts__h3">
                {{ $t('cases_page.oversecured.category') }}
              </h3>
              <span class="">{{ $t('cases_page.oversecured.category_list') }}</span>
            </div>
            <div class="about__tech-item">
              <h3 class="about__tech-desc fonts__h3">{{ $t('cases_page.oversecured.services') }}</h3>
              <span class="">{{ $t('cases_page.oversecured.services_list') }}</span>
            </div>
          </div>
          <div class="about__info">
            <div>
              <h2 class="about__info-title ">{{ $t('cases_page.oversecured.about_info') }}</h2>
            </div>
            <p class="about__info-text ">{{ $t('cases_page.oversecured.about_info_desc') }}</p>
            <div class="about__info-btn" v-for="(project, i) in cases" :key="i">
              <a class="btn__general btn__general-black" :href="project.path" rel="nofollow" target="_blank">
                {{ $t('cases_page.oversecured.project_link') }}
                <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z"
                    fill="#2E3139" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="goal">
    <div class="container">
      <div class="line-wrapper">
        <div class="goal__inner">
          <div class="goal__title">
            <h2 class="">{{ $t('cases_page.oversecured.goal_title') }}</h2>
          </div>
          <div class="goal__body">
            <p class="">{{ $t('cases_page.oversecured.goal_title_desc') }}</p>
            <p class="goal__body-title">{{ $t('cases_page.oversecured.goal_list_title') }}</p>
            <ul>
              <li class="" v-for="(content, index) in $tm('cases_page.oversecured.goal_list_items')"
                :key="index">{{ $rt(content) }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="experience">
    <div class="container">
      <div class="line-wrapper">
        <div class="experience__inner">
          <div class="experience__info">
            <div class="experience__info-wrapper">
              <div class="experience__info-col1">
                <h2 class="experience__info-title ">{{ $t('cases_page.oversecured.experience-title') }}
                </h2>
                <p>{{ $t('cases_page.oversecured.experience-desc') }}</p>
              </div>
              <div class="experience__info-col2">
                <p class="experience__info-col2__title">{{ $t('cases_page.oversecured.experience-list-title') }}</p>
                <ul>
                  <li class=" "
                    v-for="(content, index) in $tm('cases_page.oversecured.experience-list-items')" :key="index">{{ $rt(content) }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <picture>
          <source srcset="../assets/cases/oversecured/oversecured_exp.webp" type="image/webp">
          <img class="experience__image" src="../assets/cases/oversecured/oversecured_exp.png" alt="" loading="lazy" />
        </picture>
      </div>
    </div>
  </section>

  <section class="how">
    <div class="container">
      <div class="line-wrapper">
        <div class="how__inner">
          <div class="how__info">
            <div class="how__info-wrapper">
              <div>
                <h2 class="how__info-title ">{{ $t('cases_page.oversecured.how_title') }}</h2>
                <div class="how__info-text "
                  v-for="(content, index) in $tm('cases_page.oversecured.how_desc')" :key="index">{{ $rt(content) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="how__info__img_container">
          <img class="how__image1" src="../assets/cases/oversecured/oversecured_how1.png" alt="" loading="lazy" />
          <img class="how__image2" src="../assets/cases/oversecured/oversecured_how2.png" alt="" loading="lazy" />
        </div>
      </div>
    </div>
  </section>

  <section class="reports">
    <div class="container">
      <div class="line-wrapper">
        <div class="reports__inner">
          <div class="reports__info">
            <div class="reports__info-wrapper">
              <div class="reports__info-col1">
                <h2 class="reports__info-title ">{{ $t('cases_page.oversecured.reports_title') }}</h2>
              </div>
              <div class="reports__info-col2">
                <p v-for="(content, index) in $tm('cases_page.oversecured.reports_desc')"
                  :key="index">{{ $rt(content) }}</p>
              </div>
            </div>
          </div>
        </div>
        <picture>
          <source srcset="../assets/cases/oversecured/oversecured_reports.webp" type="image/webp">
          <img class="reports__image" src="../assets/cases/oversecured/oversecured_reports.png" alt="" loading="lazy" />
        </picture>
      </div>
    </div>
  </section>

  <section class="conclusion">
    <div class="container">
       <div>
         <h2 class="conclusion__title">{{ $t('cases_page.oversecured.conclusion_title') }}</h2>
       </div>
       <p class="conclusion__text" v-for="(content, index) in $tm('cases_page.oversecured.conclusion_desc')"
         :key="index" v-html="$rt(content)"></p>
    </div>
    <div class="conclusion__slider">
      <div class="container">
        <div class="conclusion__slider__pc" v-if="isPc">
          <div class="conclusion__slider__pc__col">
            <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide1.png" alt="" loading="lazy" />
            </div>
            <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide2.png" alt="" loading="lazy" />
              </div>
          </div>
          <div class="conclusion__slider__pc__col">
            <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide3.png" alt="" loading="lazy" />
            </div>
            <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide4.png" alt="" loading="lazy" />
              </div>
          </div>
          <div class="conclusion__slider__pc__col">
            <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide5.png" alt="" loading="lazy" />
            </div>
            <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide6.png" alt="" loading="lazy" />
              </div>
          </div>
        </div>
        <Swiper v-else
          :slides-per-view="1.2" 
          :space-between="50"
          >
            <SwiperSlide>
              <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide1.png" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide2.png" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide3.png" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide4.png" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide5.png" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="conclusion__slider__img">
                  <img src="../assets/cases/oversecured/oversecured_conclusion_slide6.png" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
          </Swiper>
      </div>
    </div>
  </section>

  <RecentProjects2 />
  <section class="contact">
    <Contact />
  </section>

</template>

<script>

import Contact from "../components/ContactsNew";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import RecentProjects2 from "../components/RecentProjects2";
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue.js";
import 'swiper/swiper.min.css';

export default {
  name: "Oversecured",
  components: {
    Swiper,
    SwiperSlide,
    RecentProjects2,
    Contact,
  },
  setup() {
    const siteData = reactive({
      title: "Clear design for mobile app security company OverSecured",
      description: "It's not enough to find app vulnerabilities; we need to present them visually. So, we have helped OverSecured to do this through accessible and clear design.",
      keywords: "cybersecurity, startup",
      robots: "index"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },
  data: () => ({
    isPc: window.matchMedia('(min-width: 992px)').matches,
    cases: [
      { name: 'Launch the site', path: 'https://oversecured.com/' }
    ],
    recentCases: [
      { id: 1, name: 'View all cases', path: '/cases/' }
    ],
  }),
  methods: {
    setIsPc(){
      this.isPc = window.matchMedia('(min-width: 992px)').matches;
    }
  },
  mounted() {
    window.addEventListener("resize", this.setIsPc);
  },
  unmounted() {
    document.removeEventListener('resize', this.setIsPc);
  },
}
</script>

<style lang="scss">
@import "../base/styles/media";
@import "../base/styles/fonts.scss";


.line-wrapper {
  padding-bottom: 96px;
  border-bottom: 1px solid #DADADA;
  @media(max-width: 575px) {
    padding-bottom: 48px;
  }
}

.contact {
  margin-top: 96px;
  overflow: hidden;
}

.look {
  margin-top: 154px;

  @media(max-width: 991px) {
    margin-top: 135px;
  }

  @media(max-width: 575px) {
    margin-top: 96px;
  }

  &__text {
    max-width: 465px;
    margin-top: 30px;

    @media(max-width: 991px) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 20px;
      max-width: 400px;
    }

    @media(max-width: 575px) {
      font-size: 14px;
      line-height: 24px;
      margin-top: 10px;
    }
  }
}

.presentation {
  position: relative;
  margin-top: 64px;

  .container {
    padding: 0 12px;
    @media(max-width: 575px) {
      height: 175px;
      overflow: hidden;
    }
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 45px;
    padding-top: 0;
  }

  &__image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about {
  margin-top: 64px;

  .container {
    padding: 0 12px;
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 30px;

    .container {
      padding: 0 12px 0 12px;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
      column-gap: 30px;
    }
  }

  &__tech {
    width: 100%;
    max-width: 606px;

    @media(min-width: 992px) {
      padding-top: 30px;
    }

    &-item {
      &:not(:first-child) {
        margin-top: 30px;

        @media(max-width: 991px) {
          margin-top: 30px;
        }

        @media(max-width: 575px) {
          margin-top: 0px;
        }
      }

      @media(max-width: 575px) {
        display: flex;
        align-items: center;
      }
    }

    &-desc {
      position: relative;
      @media(max-width: 575px) {
        position: relative;
        &:after {
          content: ':';
          position: absolute;
          top: 0;
          right: -5px;
          display: inline-block;
          margin-left: -3px;
        }
      }

      @media(max-width: 575px) {

        &:after {
          content: ':';
          position: absolute;
          bottom: 0;
          right: -5px;
          display: inline-block;
        }
      }
    }

    span {
      display: inline-block;

      color: #2E3139;

      @media (min-width: 576px) {
        margin-top: 10px;
      }

      @media (max-width: 575px) {
        margin-left: 10px;
      }
    }
  }

  &__info {
    width: 100%;

    @media(max-width: 991px) {
      margin-top: 80px;
    }

    @media(max-width: 575px) {
      margin-top: 30px;
    }

    &-title {
      color: #2E3139;
    }

    &-text {
      max-width: 502px;

      margin-top: 30px;

      @media(max-width: 991px) {
        margin-top: 20px;
      }

      @media(max-width: 575px) {
        margin-top: 10px;
      }
    }

    &-btn {
      margin-top: 30px;

      @media(max-width: 991px) {
        margin-top: 30px;
      }

      @media(max-width: 575px) {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.goal {
  margin-top: 96px;

  .container {
    padding: 0 12px;
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 30px;

    .container {
      padding: 0 12px 0 12px;
    }
  }

  ul {
    @extend .fonts__ul;
  }
 
  &__inner {
    display: flex;
    //justify-content: space-between;
    column-gap: 30px;
    @media(max-width: 575px) {
      flex-direction: column;
    }
  }

  &__body {
    p:nth-child(1){
      margin-top: 0;
      @media(max-width: 575px) {
        margin-top: 10px;
      }
    }
    p{
      max-width: 601px;
    }
    &-title {
      margin-top: 30px;
      font-weight: 700;
      font-size: 24px;
      @media(max-width: 575px) {
        font-size: 18px;
      }
    }
  }

  &__title {
    width: 100%;
    max-width: 601px;
  }

  &__main {
    flex: 0 1 502px;
    padding-top: 20px;

    @media(max-width: 575px) {
      padding-top: 10px;
    }

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    span {
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.how,
.experience,
.reports {
  margin-top: 96px;
  .container {
    img {
      margin-top: 64px;
      width: 100%;
      @media(max-width: 575px) {
        margin-top: 30px;
      }
    }
  }

  .reports__info-title {
    max-width: none;
  }

  .reports__info-col2 {
    p {
      margin-bottom: 20px;
      margin-top: 0;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  &__info__img_container {
    width: 100%;
    margin-top: 64px;
    padding: 0 30px;
    background: rgba(7, 32, 68, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media(max-width: 575px) {
      margin-top: 48px;
    }

    .how__image1 {
      max-width: 1070px;
    }

    .how__image2 {
      position: relative;
      top: -220px;
      max-width: 1070px;
      margin-bottom: -180px;

      @media(max-width: 768px) {
        top: 0px;
        margin-bottom: 30px;
      }
    }
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 30px;

    .container {
      padding: 0 12px 0 12px;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__info {
    &-col1 {
      width: 100%;
      max-width: 601px;

      h2 {
        width: 100%;
        max-width: 412px;
        margin-bottom: 10px;
      }
    }

    &-col2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &__title {
        @extend .goal__body-title;
       margin-top: 30px;
      }

      ul {
        @extend .fonts__ul;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      column-gap: 30px;

      @media(max-width: 991px) {
        flex-direction: column;
        column-gap: 0;
      }
    }

    &-text {
      max-width: 554px;
      @extend p;

      @media(max-width: 991px) {
        margin-top: 20px;
      }

      @media(max-width: 575px) {
        margin-top: 10px;
      }
    }

    span {
      display: inline-block;
      color: #2E3139;
      margin-top: 30px;

    }

    ul {
      @extend .fonts__ul;
    }
  }

  &__site {
    flex: 0 1 606px;

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    @media(max-width: 1399px) {
      flex: 0 1 560px;
    }

    @media(max-width: 1199px) {
      flex: 0 1 470px;
    }

    @media(max-width: 991px) {
      margin-top: 60px;
    }

    img {
      width: 100%;
    }
  }
}

/////


.conclusion {
  margin-top: 48px;

  @media (max-width: 991px) {
    margin-top: 48px;
  }

  @media (max-width: 575px) {
    margin-top: 45px;
  }
  &__slider{
    background: #E1E8F0;
    padding: 64px 0;
    margin-top: 96px;
    @media (max-width: 575px) {
      padding: 30px 0;
      margin-top: 48px;
    }
    &__pc{
      display: flex;
      column-gap: 64px;
      justify-content: center;
      &__col:nth-child(2){
        margin-top: 150px;
      }
    }
    &__img{
      width: 100%;
      max-width: 368px;
      img{
        width: 100%;
      }
    }
  }

  &__text {
    max-width: 601px;
    margin-top: 30px;

    a {
      text-decoration: underline;
      font-size: inherit;
      color: #3675af;
    }

    @media(max-width: 992px) {
      margin-top: 20px;
    }

    @media(max-width: 575px) {
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.project{
  padding-top: 112px !important;
  @media(max-width: 575px) {
    h2{
        font-size: 24px !important;
    }
    padding-top: 48px !important;
  }
}

</style>
